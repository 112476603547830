.badge{
    border-radius: 50%;
    text-align: center;
    white-space: normal;
    z-index:22;
}

@media screen and (min-width: 768px){
    .is-portal-page .badge{
        top:40px;
    }
}

.liveislife-badge {
    display: block;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    bottom: -15px;
    z-index: 50;

    @media screen and (min-width: 768px){
        width: 150px;
        height: 150px;
        bottom:auto;
        right: 15px;
        top: -25px;
        transition: all 200ms ease;
    }
}
/*@media screen and (min-width: 768px){
    .liveislife-badge:hover,
    .liveislife-badge:focus{
        transform: rotate(5deg);
    }
}*/
.liveislife-badge__img {
    display: block;
    width: 100%;
    height: auto;
}

.badge--promotion{
    width: 90px;
    height: 90px;
    background-color: #766f6a;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    position: absolute;
    left: 5px;
    top: -18px;
    z-index: 50;

    @media screen and (min-width: 768px){
        top: -25px;
        width: 165px;
        height: 165px;
        transition: all 200ms ease;
    }

    @media screen and (min-width: 1360px){
        top: 85px;
        left: 40px;
    }
}

.content-badge .badge--promotion{
    @media screen and (max-width: 767px) {
        width: 75px;
        height: 75px;
        left: 8px;
        top: 44px;
        position: relative;
    }
}


.badge--promotion--xs-left-bottom{
    @media screen and (max-width: 767px){
        top:100px;
    }
}

.badge--promotion:hover {
    @media screen and (min-width: 768px){
        transform: rotate(10deg) scale(1.1);
    }
}

.badge--promotion .badge__inner{
    z-index: 0;
    position: absolute;
    right: 2px;
    left: 2px;
    top: 2px;
    bottom: 2px;
    border-radius: 50%;
    border: 2px solid #fff;
    transform: rotate(-15deg);
    padding: 20px 5px;

    @media screen and (min-width: 768px){
        right: 4px;
        left: 4px;
        top: 4px;
        bottom: 4px;
        border: 4px solid #fff;
    }
}

.content-badge .badge--promotion .badge__inner{
    @media screen and (max-width: 767px){
        padding: 14px 5px;
    }
}

/* arosa */
.portal-arosa .badge--promotion{
    background:var(--color-arosa);
}
.portal-lenzerheide .badge--promotion{
    background:var(--color-lenzerheide);
}


.badge__hl{
    font-size: 12px;
    line-height: 1;
    white-space: normal;

    @media screen and (min-width: 768px){
        font-size: 20px;
    }
}

.content-badge .badge__link{
    @media screen and (max-width: 768px) {
        font-size: 11px;
        margin: 1px 0;
    }
}

.badge__link{
    font-size: 12px;
    line-height: 17px;
    font-family: var(--font-light-family);
    margin: 5px 0;

    @media screen and (min-width: 768px){
        margin: 8px 0;
        font-size: 14px;
    }
}

.badge__link>.icon{
    font-size: 11px;
    position: relative;
    top: 1px;
    margin-left: 3px;
    @media screen and (max-width: 767px){
        display:none;
    }
}

.badge__stars{
    font-size: 22px;
    letter-spacing: 2px;
    font-family: var(--font-bold-family);
}

.badge--cart{
    width: 70px;
    height: 70px;
    background: #fff;
    box-shadow: 0 1px 9px rgba(0, 0, 0, 0.2);
    position: absolute;
    right: 8px;
    top: -20px;

    @media screen and (min-width: 768px){
        width: 177px;
        height: 177px;
        right:35px;
        top: -25px;
        transition: transform 350ms cubic-bezier(.68,-.55,.27,1.55);
    }
}

.badge--cart-small{
    right: 10px;
    top: 10px;
    width: 80px;
    height: 80px;
    @media screen and (min-width: 768px) and (max-width: 1299px){
        width: 120px;
        height: 120px;
        right: 15px;
        top: calc(100vh - 450px)!important;
    }
    @media screen and (min-width: 1300px) {
        right:35px;
        top: -25px;
        width: 120px;
        height: 120px;
    }
}

@media screen and (min-width: 768px){
    .badge--cart a {
        transition: all 0.15s linear;
    }
    .badge--cart a:hover {
        transform: translateY(-4px);
    }
}

.badge--cart .badge__inner{
    position: absolute;
    top:4px;
    bottom: 4px;
    right: 4px;
    left:4px;
    border-radius: 50%;
    background-color: #fec900;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: inset 0 1px 17px rgba(223, 177, 2, 0.7);
    padding: 15px;

    @media screen and (min-width: 768px){
        padding: 20px;
    }
}

.badge--cart-small .badge__inner a{
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top:50%;
    transform: translateY(-50%);
}
.badge--cart-small .badge__inner a:hover{
    transform: translateY(-50%);
}
.badge--cart-small:hover{
    transform:rotate(10deg) scale(1.1);
}

.badge--cart .badge__top__text{
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.85px;
    font-family: var(--font-light-family);
    text-transform: uppercase;
}

.badge--cart .badge__top .icon{
    font-size: 26px;
    @media screen and (min-width: 768px){
        font-size: 31px;
        margin-bottom: 10px;
    }
}

.badge--cart .badge__top{
    position: relative;
    @media screen and (min-width: 768px){
        padding-bottom: 10px;
    }
}
.badge--cart-only .badge__top{
    @media screen and (min-width: 768px){
        margin-top:30px;
    }
}

.badge--cart .badge__top--with-lbl{
    @media screen and (max-width: 767px){
        top:-6px;
    }
}

.badge--cart .badge__bottom{
    padding-top: 10px;
}
.badge--cart-only .badge__bottom{
    display: none !important;
}

@media screen and (min-width: 768px){
    .badge--cart .badge__top:after{
        content: '';
        position: absolute;
        bottom:0;
        height: 1px;
        background: #fff;
        left:0;
        right: 0;
    }
    .badge--cart-only .badge__top:after{
        display: none;
    }
}

.badge--cart__counter{
    width: 32px;
    height: 32px;
    background: #fff;
    box-shadow: 0 1px 9px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    position: absolute;
    z-index: 3;
    top:-4px;
    right: -8px;

    @media screen and (min-width: 768px){
        width: 52px;
        height: 52px;
        top:0;
        right: -4px;
    }
}

.badge-counter__figure{
    font-size: 16px;
    color: #000;
    position: absolute;
    top:50%;
    left:50%;
    transform: translateY(-50%) translateX(-50%);

    @media screen and (min-width: 768px){
        font-size: 20px;

    }
}



.corner-badge {
    position: absolute;
    left: -6.125rem;
    top: -2.75rem;
    z-index:10;
}
.corner-badge__inner{
    padding: .375rem 2.5rem;
    font-size:1.25rem;
    line-height: 1.5;
    color:#fff;
    font-family: var(--font-themix-bold);
    transform-origin: right center;
    transform: rotate(-45deg);
    text-align: center;
    width: 15.625rem;
}