.breadcrumb-wrapper{
    @media screen and (min-width: 768px){
        margin-left: 220px;
        margin-right: 220px;
        padding: 25px 20px;
    }
}

.breadcrumb-wrapper.breadcrumb-wrapper--with-badge{
    padding-bottom: 80px;
}

@media screen and (max-width: 767px){
    .breadcrumb-wrapper{
        position: relative;
        padding-top:65px;
        margin-bottom:-25px;
    }
    .breadcrumb-wrapper.no-cart-badge{
        padding-top:12px;
    }
    .breadcrumb-wrapper--with-badge{
        padding-bottom: 70px;
    }
    .breadcrumb-wrapper:after{
        content:'';
        display: block;
        position:absolute;
        right:15px;
        top:0;
        bottom:0;
        width:60px;
        height:auto;
        pointer-events: none;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f3f3f3+0,f3f3f3+100&0+0,1+100 */
        background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
        z-index:6;
    }
    .breadcrumb-item {
        float:none;
        display: inline-block;
    }
    .breadcrumb>li:last-child{
        margin-right:35px;
    }
}

/*.breadcrumb-wrapper.no-cart-badge{
    margin-left: 0;

    @media screen and (max-width: 767px){
        padding-top:25px;
    }
}*/ 

.breadcrumb{
    padding: 0;
    background: transparent;
    margin:0;
    z-index:5;

    @media screen and (max-width: 767px){
        display:block;
        position: relative;
        padding:10px 0;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        z-index:5;
    }
}

.breadcrumb>li>span,
.breadcrumb>li>a{
    color: var(--color-lightgrey);
    font-size: 14px;
    padding: 5px 8px;
}
.breadcrumb>li:first-child>a>.icon{
    position: relative;
    top:1px;
    margin-right:10px;
}

.breadcrumb>li.active>span,
.breadcrumb>li.active>a{
    color: #544b45;
}
.breadcrumb-item+.breadcrumb-item:before {
    color: var(--color-lightgrey);
}
@media screen and (max-width: 767px){
    .breadcrumb-item+.breadcrumb-item:before{
        padding: 0;
    }
}