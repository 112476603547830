body {
    position: relative;
    background-attachment: fixed;
    background-color: #d9d9d9;
}

body.is-fake-overlay{
    background-image: none;
    background: #d8d8d8;
}

body.is-blog{
    background-image: none;
    background: #DCDBD9;
}

.page-wrapper {
    position: relative;
    max-width: 1400px;
    margin: 0 auto;
    margin-top: 0;

    @media screen and (min-width: 768px){
        margin-top: 70px;
    }
}

.page-wrapper.page-wrapper--pistenpano {
    margin-top:-5px;
    max-width:100%;
}

@media screen and (max-width: 767px){
    .is-blog .page-wrapper{
        margin-top: 0;
    }
}


.container {
    max-width: 1360px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.container.container--small{
    max-width: 1000px;
}

#demi-infrastructure-content .container.container--small{
    @media screen and (min-width: 768px) and (max-width: 1200px) {
        margin-top: 70px;
    }
}

.container-outer{
    padding-top: 65px;
    @media screen and (min-width: 768px){
        padding-top: 100px;
    }

}

@media screen and (max-width: 767px){
    .is-fake-overlay .container-outer{
        padding-top: 0;
    }
}


.main-section{
    background: #fff;
    margin-bottom: 25px;
    padding-bottom: 20px;
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.4);

    @media screen and (min-width: 768px){
        padding-bottom: 35px;
    }
}

.main-section:last-of-type{
    margin-bottom: 70px;
}

.main-section.main-section--portal {
    padding-bottom: 0;
}

.page-wrapper--pistenpano .main-section.main-section {
    margin:0;
    padding:0;
}

.main-section--grid{
    padding-top: 30px;
    padding-bottom: 1px;
    @media screen and (min-width: 768px){
        padding-top: 50px;
        padding-bottom: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    body {
        width: 982px;
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: 320px;
        max-width: 100%;
        overflow-x: hidden;
    }
    html {
        overflow-x: hidden;
    }

    .is-blog .main-section{
        margin-bottom: 40px;
    }

}