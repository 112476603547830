
@media screen and (max-width: 767px) {
    .navbar-main {
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.125);
    }
    html.has-overflow-hidden, .has-overflow-hidden body {
        overflow: hidden;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .mobile-nav {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        padding-top: 0;
        transform: translateX(-100%);
        transition: transform 200ms ease-in-out;
        z-index: 9999;
    }
    .mobile-nav__content {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 30px 20px;
    }

    .mobile-nav.is-open {
        transform: translateX(0);
    }
    .mobile-nav-container {
        background: #fff;
    }
    .mobile-nav-container .navbar-nav {
        margin: 0;
    }
    .navbar-nav>li.is-open>.main-nav__dropdown {
        display: block;
    }

    .navbar-left__nav{
        margin-top: 40px;
        margin-bottom:25px;
    }

    .navbar-left__nav>li>a{
        font-size: 27px;
        line-height: 32px;
    }

    /*subnav*/
    .navbar-subnav {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 10px;
        right: 0;
        transform: translateX(220%);
        transition: transform 250ms ease-in-out;
        z-index:1;
        padding-bottom: 48px;
        overflow: hidden;
        overflow-y: scroll;
        display: block;
        background: #FFF;
        text-align: left;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
    }

    .navbar-sub-subnav {
        left: 10px;
    }

    .navbar-subnav li:not(:last-of-type){
        border-bottom: 1px solid #e2e2e2;
    }

    .navbar-left__nav li.is-open>.navbar-subnav {
        transform: translateX(0);
    }
    .navbar-main__dropdown__main {
        margin: 0 -15px;
    }
    .navbar-top__regionchanger .region-switch {
        font-size: 24px;
        display: inline-block;
        position: relative;
        padding: 14px 10px 8px 10px;
        margin: 0;
    }
    .xs-header-langswitch.xs-header-langswitch {
        font-size: 14px;
        padding-left:0;
        padding-right: .75rem;
    }
    .xs-header-langswitch .icon {
        position: relative;
        margin-right:.25rem;
        top:2px;
        font-size: 20px;
    }

    .xs-header-langswitch-label {
        margin-top: .15rem;
    }

    .xs-header-search-container{
        position: absolute;
        right:58px;
        top:2px;
    }
    .xs-header-search-container--no-burger{
        right:18px;
    }

    .xs-header-search{
        display: inline-block;
        position: relative;
        padding: 14px 8px;
        margin:6px 0;
    }
    .xs-header-search>.icon{
        position: relative;
        top:2px;
        font-size:22px;
    }
    .header-searchbar {
        position: fixed;
        left:-8px;
        right:-8px;
        top:67px;
        padding:20px 28px;
        background:#fff;
        box-shadow: inset 0 0px 5px 0 rgba(0,0,0,.25);
    }



    .navbar-main--micrositeheader .xs-header-navbar-toggler-container{
        position: absolute;
        right:15px;
        top:2px;
    }

    .navbar-toggler{
        position: relative;
        padding: 14px 8px;
        margin:10px 10px 10px 5px;
    }
    .navbar-main--micrositeheader .navbar-toggler{
        margin:10px 0 10px -10px;
    }

    .navbar-toggler .icon-bar{
        background: #38312D;
        width: 27px;
        height: 2px;
        border-radius: 0;
        display: block;
    }

    .navbar-toggler .icon-bar+.icon-bar {
        margin-top: 5px;
    }
    .navbar-left__nav>li>.navbar-subnav>ul>li>a {
        display: block;
        font-size: 24px;
        padding: 12px 20px;
        margin:0;
    }
    .navbar-left__nav .navbar-subnav li>a>.icon {
        font-size:15px;
        position: absolute;
        right: 20px;
        top: 16px;
        transform: scale(-1, 1);
    }
    .navbar-sub-subnav>ul>li>a {
        padding: 10px 20px;
        display: block;
        line-height: 1.1;
    }
    .navbar-sub-subnav .navbar-sub-subnav ul>li>a>.icon {
        display: none;
    }
    .navbar-subnav__back {
        display: inline-block;
        padding:30px 20px;
    }
    .navbar-subnav__back>.icon {
        margin-right:10px;
    }
}

