.header-console{

}



/* overlays */
.header-console-overlay{
    position: absolute;
    top:100px;
    left:0;
    width: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    background-color:rgba(255, 255, 255, 0.95);
    padding:2.5rem 0 1.5rem 0;
    transform:translateX(-120%);
    opacity:0;

    @media screen and (max-width:767px){
        padding:4rem 0 8rem 0;
        overflow-y:scroll;
        position:fixed;
        left:0;
        right:0;
        top:67px;
        bottom:74px;
    }
}


header.affix .header-console-overlay{
    @media screen and (min-width:768px){
        top:55px;
    }
}


.header-console-overlay.is-open {
    transform:translateX(0);
    opacity:1;
}

.container.container--console-overlay{
    max-width: 750px;
}
.container.container--console-overlay.facilities-wrapper{
    max-width: 900px;
}
.container.container--console-overlay-narrow{
    max-width: 400px;
}
