.watch-on-badge {
    position: absolute;
    z-index: 200;
    left: 0;
    bottom: -23px;
    max-width: 180px;
}
@media screen and (min-width: 768px) {
    .watch-on-badge {
        bottom: auto;
        top: 130px; /* -103px */
        max-width: 300px;
    }
}

.watch-on-badge__content {
    background-color: var(--color-lenzerheide);
    color: white;
    border-radius: 0;
    transition: unset;
}